function getCookie(name) {
  var cookie = document.cookie.split('; ').find(row => row.startsWith(name+'='))
  if (cookie) {
    cookie.split('=')[1];
  } else {
    return null;
  }
}

function setCookie(name, value) {
  document.cookie = name+"="+value;
}

export { getCookie, setCookie }
