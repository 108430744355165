$(document).on('ready turbolinks:load', function() {
  $('a.btn-stripe-checkout').on('click', function(e) {
    var sessionId = $(this).data('stripe-session-id');
    var publishableAPIKey = $(this).data('stripe-publishable-key');

    var stripe = Stripe(publishableAPIKey);

    stripe.redirectToCheckout({
      sessionId: sessionId
    }).then(function (result) {
      alert(result.error.message);
    });
  });
});
