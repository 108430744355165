// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("bootstrap/dist/js/bootstrap")
require("js-cookie/src/js.cookie")
// require("datatables.net/js/jquery.dataTables")
// require("datatables.net-bs4/js/dataTables.bootstrap4")

//require("channels")
//require("trix")
//require("@rails/actiontext")

require('jquery')
window.$ = $

import('controllers')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// Polyfills
import './custom_event_polyfill.js'
import './foreach_polyfill.js'
// TODO old assets javascript files, port these!
import './video_lessons.js'
import './stripe.js'

// TODO Legacy javascript from old assets pipeline (port this!)
$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
});

$(document).on('ready turbolinks:load', function() {
  $(".alert.fade").delay(4000).slideUp(600, function() {
    $(this).alert('close');
  });
  $('[data-toggle="tooltip"]').tooltip();
});

// songs.js
// import DataTable from 'datatables.net';
//
// $(document).on('ready turbolinks:load', function() {
//   $('table.songs.data-table').DataTable({lengthChange: false, paging: false, dom: 'ftip'});
// });
